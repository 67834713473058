<template>
  <a-layout :style="{minHeight: '100vh'}">
    <a-layout-header>
      <div class="ant-content" style="color: white;">
        <a-avatar src="/logo.jpg" alt="Калькулятор расчета калорий для женщин" />
        Калькулятор калорий
      </div>
    </a-layout-header>
    <a-layout-content :style="{ padding: '24px 0'}">
      <div class="ant-content">
        <a-form :model="formState" layout="vertical">
          <a-row :gutter="[16,16]">
            <a-col :xs="24" :sm="24" :md="9" :lg="8" :xl="7">
              <a-form-item label="Вес">
                <a-radio-group v-model:value="formState.mass" button-style="solid">
                  <a-radio-button value="gain">{{ massText['gain'] }}</a-radio-button>
                  <a-radio-button value="lose">{{ massText['lose'] }}</a-radio-button>
                  <a-radio-button value="maintain">{{ massText['maintain'] }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="8" :md="5" :lg="6" :xl="6">
              <a-form-item label="Ваш рост">
                <a-input-number v-model:value="formState.height" :min="100" :max="210" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="8" :md="5" :lg="5" :xl="6">
              <a-form-item label="Ваш возраст">
                <a-input-number v-model:value="formState.age" :min="10" :max="70" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="8" :md="5" :lg="5" :xl="5">
              <a-form-item label="Ваш вес">
                <a-input-number v-model:value="formState.weight" :min="10" :max="1000" style="width: 100%;" />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="9" :lg="8" :xl="7">
              <a-form-item label="Выберите Вашу активность">
                <a-radio-group v-model:value="formState.activity">
                  <a-radio :style="radioStyle" value="1.2">Малоактивные</a-radio>
                  <a-radio :style="radioStyle" value="1.375">Новички (1-3 тренировок в неделю)</a-radio>
                  <a-radio :style="radioStyle" value="1.55">Средняя (4-5 тренировок в неделю)</a-radio>
                  <a-radio :style="radioStyle" value="1.725">Высокая (6-7 тренировое в неделю)</a-radio>
                  <a-radio :style="radioStyle" value="1.9">Сверхвысокая (более 7 тренировок)</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="15" :lg="16" :xl="17">
              <a-table :dataSource="dataSource" :columns="columns" :pagination="false" bordered>
                <template #summary>
                  <a-table-summary fixed>
                    <a-table-summary-row>
                      <a-table-summary-cell :index="0">Общее кол-во</a-table-summary-cell>
                      <a-table-summary-cell :index="1"> {{ kcalOnSet }} ккал </a-table-summary-cell>
                    </a-table-summary-row>
                  </a-table-summary>
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <div class="ant-content"></div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import { defineComponent, reactive, toRaw, computed,ref } from 'vue';
export default defineComponent({
  setup() {
    const formState = reactive({
      height: 170, // x
      age: 35, // y
      weight: 60, // z
      activity: '1.55', // t
      mass: ref('gain'),
      percent: 15,
      protein: 2,
      fats: 1,
    })
    const radioStyle = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    })
    const proteinGramm = computed(() => {
      return Math.ceil(formState.weight * formState.protein)
    })
    const fatsGramm = computed(() => {
      return Math.ceil(formState.weight * formState.fats)
    })
    const carbsKkal = computed(() => {
      return Math.ceil(kcalOnSet.value - ((proteinGramm.value * 4) + (fatsGramm.value * 9)))
    })
    const dataSource = reactive([
      {
        total: 'Белки',
        kkal: computed(() => Math.ceil(proteinGramm.value * 4)),
        gramm: computed(() => proteinGramm.value),
      },
      {
        total: 'Жиры',
        kkal: computed(() => Math.ceil(fatsGramm.value * 9)),
        gramm: computed(() => fatsGramm.value),
      },
      {
        total: 'Углеводы',
        kkal: computed(() => carbsKkal.value),
        gramm: computed(() => Math.ceil(carbsKkal.value / 4)),
      },
    ])
    const columns = [
      {
        title: 'Итого',
        dataIndex: 'total',
        key: 'total',
      },
      {
        title: 'Ккал',
        dataIndex: 'kkal',
        key: 'kkal',
      },
      {
        title: 'Граммы',
        dataIndex: 'gramm',
        key: 'gramm',
      },
    ]

    const BMR = computed(() => {
      return Math.ceil(447.593 + (9.247 * formState.weight) + (3.098 * formState.height) - (4.330 * formState.age))
    })
    const AMR = computed(() => {
      return Math.ceil(BMR.value * formState.activity)
    })
    const kcalOnSet = computed(() => {
      // AMR (активный метаболизм) + AMR (активный метаболизм) * 15 %  = ккал на наборе
      const pr = AMR.value / 100 * formState.percent
      switch (formState.mass) {
        case 'gain':
          return Math.ceil(AMR.value + pr)
        case 'lose':
          return Math.ceil(AMR.value - pr)
        case 'maintain':
          return Math.ceil(AMR.value)
      }
    })
    const massText = {
      gain: 'Набрать',
      lose: 'Сбросить',
      maintain: 'Поддерживать',
    }

    const onSubmit = () => {
      console.log('submit!', toRaw(formState));
    }

    return {
      AMR,
      BMR,
      massText,
      kcalOnSet,
      radioStyle,
      formState,
      columns,
      dataSource,
      onSubmit,
    };
  },

});
</script>
<style>
.ant-layout {
  min-height: 100vh;
}
.ant-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px;
}
span.ant-avatar {
    margin: -2px 5px 0 0;
}
.ant-layout-header {
    margin-bottom: 40px;
    box-shadow: 0 7px 14px rgba(0,0,0,0.25);
    background: linear-gradient(#002829, #001529) !important;
}
</style>
